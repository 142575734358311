<template>
  <v-tooltip
    content-class="custom-views-help-tip"
    bottom
    :disabled="!showTooltip">
    <template #activator="{ on }">
      <v-list-item-subtitle v-on="on">
        <v-checkbox
          :input-value="value"
          :disabled="showTooltip"
          :ripple="false"
          hide-details
          class="mt-0"
          color="lightBlue"
          style="max-width: 75px"
          label="Export types by custom view"
          @change="$emit('change', $event)" />
      </v-list-item-subtitle>
    </template>
    <TooltipContent />
  </v-tooltip>
</template>
<script>
export default {
  name: 'ProjectDetailsExportToByCustomViewBlock',
  components: {
    TooltipContent: () => import('./ProjectDetailsExportToTooltipContent'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
